<template>
  <div class="main-page">
    <div class="product-top-bg"></div>
      <div class="product-list-container">
        <div class="product-list-box" v-for="(parentItem, parentIndex) of productionList" :key="parentIndex">
          <div class="product-list-title">{{ parentItem.listTitle }}</div>
          <div class="product-list-item" v-for="(childItem, childIndex) of parentItem.listArr" :key="childIndex">
          <div>
            <img class="product-item-icon" :src="childItem.productIcon"> 
          </div>
          <div class="product-item-title">{{ childItem.productTitle }}</div>
          <div class="product-item-content">{{ childItem.productContent }}</div>
          <div class="product-item-character">{{ childItem.productCharacter }}</div>
        </div>
      </div>
    </div>
    <div class="chapter-menu-btn"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      productionList: [
        {
          listTitle: '火脸收银设备',
          listArr: [
            {
              productIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_code_plate.png',
              productTitle: '火脸收款码牌',
              productContent: '轻松搞定多平台收款的二维码；适用场景如社区小店、流动摊位，高频收银轻松应对，携带便捷。',
              productCharacter: '成本低 | 聚合支付 | 操作简单'
            },
            {
              productIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_pos.png',
              productTitle: '火脸POS-联迪A8',
              productContent: '是目前行业最好用的收款工具，适用场景如医美、休闲娱乐、教育培训、连锁餐饮等，响应速度快，使用简单，安全性高。',
              productCharacter: '速度快 | 使用简单 | 性能强 | 安全性高'
            },
            {
              productIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_scan_QM.png',
              productTitle: '火脸扫码王-联迪QM50S',
              productContent: '语音播报不漏单，轻松掌握收银情况；可手持，也可置于桌面进行收银；视窗清晰，轻松被扫；4G + WiFi，通讯更稳定。',
              productCharacter: '防漏单 | 大音量 | 长待机'
            },
            {
              productIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_cloud_sound.png',
              productTitle: '火脸云音响',
              productContent: '解决多平台收款的二维码；实时收款到账提醒，防止漏单；大容量电池续航时间更久，组装方便，适用于各种场所使用。',
              productCharacter: '防漏单 | 大音量 | 长待机 | 组装方便'
            },
            {
              productIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_scan_box.png',
              productTitle: '火脸扫码盒',
              productContent: '能够快速识别二维码，无需手动操作；轻松对接各种收银系统，实现完美兼容；语音提示，收银情况实时掌握；智能化设计，更加方便。',
              productCharacter: '识别快 | 长待机 | 操作简单 | 组装方便'
            }
          ]
        },
        {
          listTitle: '火脸数字化应用',
          listArr: [
            {
              productIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_app.png',
              productTitle: '火脸收银APP',
              productContent: '交易数据清晰明了，方便用户查看；实时账本，多门店管理，轻松对账记账；资金到账快，支付方式一目了然；可以下载收银物料。',
              productCharacter: '收款快 | 对账简单 | 结算清晰'
            },
            {
              productIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_mini_program.png',
              productTitle: '火脸GO小程序',
              productContent: '清晰查看商户交易数据和佣金；无需电脑设备，直接为商户入件；可以直接在小程序获取实名认证码；方便物料下载及绑定收银设备。',
              productCharacter: '入件快 | 操作简单 | 使用便捷'
            }
          ]
        },
        {
          listTitle: '火脸插件',
          listArr: [
            {
              productIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_plugin.png',
              productTitle: '火脸收银插件',
              productContent: '轻松改造传统收银台，满足更全面的收款需求；功能简单齐全，可完全接管你的收银台；支持多平台，适用的场景丰富。',
              productCharacter: '操作简单 | 适配简单 | 功能齐全'
            }
          ]
        } 
      ]
    }
  }
}
</script>
<style lang="less" scoped>
  .main-page {
    background-color: #f1f3f7;
    .product-top-bg {
      width: 100%;
      height: 180px;
      background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/product_top_bg.png');
      background-repeat: no-repeat;
      background-position: 0  0;
      background-size: 100% auto;
    }
    .product-list-container {
      padding-bottom: 20px;
    }
    .product-list-box {
      .product-list-title {
        margin: 20px 16px 24px 16px;
        font-size: 20px;
        font-weight: 500;
        color: #000;
        text-align: left;
      }
      .product-list-item {
        margin: 0 16px 24px 16px;
        padding-bottom: 15px;
        background-color: #fff;
        border-radius: 20px;
        overflow: hidden;
        .product-item-icon {
          width: 100%;
          height: 228px;
        }
        .product-item-title {
          margin: 10px 16px 0 16px;
          font-size: 18px;
          font-weight: 500;
          color: #000;
          text-align: left;
        }
        .product-item-content {
          margin: 10px 16px 0 16px;
          font-size: 14px;
          color: #333335;
          line-height: 20px;
          text-align: left;
        }
        .product-item-character {
          margin: 30px 16px 0 16px;
          font-size: 12px;
          color: #9696a0;
          text-align: left;
        }
      }
    }
  }
</style>