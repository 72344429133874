<template>
  <div class="home-body">
    <div class="home-page">
      <transition name="animation" mode="out-in">
        <div key="homeBox" v-if="!showRegister" class="home-box" style="min-width: 300px;min-height: 300px">
          <img class="box-title" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_logo_txt.png" alt="">
          <div class="box-content">火脸诞生于杭州，以“用火脸、多赚点”为使命，我们提供专业的移动支付收款、场景解决方案、供应链云资金、会员营销等服务，为用户创造更多收益。火脸整合支付通道、银行资源、SAAS系统，为支付服务商打造一套功能丰富、配置灵活的系统，为商户提供更多场景解决方案及营销功能。目前已经为服务200+支付服务商超过50000+门店使用火脸系统，我们为每一个合作的支付服务商提供专属1v1企业微信群7*12小时服务。</div>
          <div class="reg-btn" @click="clickRegister">免费注册</div>
        </div>
        <div key="regBox" v-else class="home-box" style="min-width: 300px;min-height: 300px">
          <img class="reg-code" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_regist_code.png" alt="注册码">
          <div class="reg-content">具体详情可以扫码添加运营企业微信或致电：<span class="bold">4000-818-661</span>进行咨询了解</div>
          <div class="confirm-btn" @click="clickConfirm">确认</div>
        </div>
      </transition>
    </div>
    <pc-bottom bottomClass="home-bottom"></pc-bottom>
  </div>
</template>

<script>
  import PcBottom from './PcBottom.vue'
  export default {
    components: { PcBottom },
    data() {
      return {
        showRegister: false
      }
    },
    props: {
      showRegisterBox: {
        type: Boolean,
        value: false
      }
    },
    watch: {
      showRegisterBox(newVal) {
        this.showRegister = newVal
      }
    },
    created() {
      this.showRegister = this.showRegisterBox
    },
    methods: {
      clickRegister() {
        this.showRegister = true
      },
      clickConfirm() {
        this.showRegister = false
        this.$emit('clickNavItem', 'home')
      }
    } 
  }
</script>

<style lang="less">
  .home-body {
    width: 100%;
    height: 100%;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_home_bg.jpg') no-repeat;
    background-size: cover;
    .home-page {
      height: 85vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .home-box {
        width: 550px;
        margin-top: 100px;
        border-radius: 26px;
        background-color: rgba(255, 255, 255, .85);
        box-shadow: 0px 2px 40px 0px rgba(95,114,146,0.26);
        overflow: hidden;
        transition: .3s;
        .box-title {
          width: 268px;
          height: 40px;
          margin: 8% auto 7%;
        }
        .box-content {
          padding: 0 40px;
          font-size: 20px;
          font-weight: 500;
          color: rgba(0,0,0,0.8);
          line-height: 160%;
          letter-spacing: 1px;
          text-align: justify;
        }
        .reg-btn,
        .confirm-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 368px;
          height: 60px;
          margin: 8% auto 8%;
          border-radius: 8px;
          font-size: 21px;
          font-weight: 600;
          color: #FFF;
          background-color: #FF1C6D;
          cursor: pointer;
        }
        .reg-code {
          width: 200px;
          height: 200px;
          margin: 12% auto 0;
          border: 1px solid #ccc;
        }
        .reg-content {
          width: 420px;
          margin: 10% auto 12%;
          font-size: 24px;
          line-height: 31px;
          letter-spacing: 1px;
          color: rgba(0,0,0,0.85);
          text-align: justify;
          .bold {
            font-weight: 600;
          }
        }
      }
    }
    .home-bottom {
      width: 100%;
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%, rgba(0,0,0,0.4) 100%);
      backdrop-filter: blur(1px);
      -webkit-backdrop-filter: blur(1px);
      .content-item,
      .call-item {
        opacity: 1;
      }
      .copyright,
      .recode-text {
        opacity: .8;
      }
      .content-item:hover {
        font-weight: 600;
      }
      .copyright:hover,
      .recode-text:hover {
        opacity: 1;
      }
    }
  }
  .animation-enter-from,
  .animation-leave-to {
    opacity: 0;
  }
  .animation-enter-active,
  .animation-leave-active {
    transition: opacity .4s ease;
  }
  .animation-enter-active {
    animation: identifier .4s ease;
  }
  .animation-leave-active {
    animation: identifier .4s ease reverse;
  }
  @keyframes identifier {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
</style>