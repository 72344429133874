<template>
  <div class="main-page">
    <div class="fix-nav-bar">
      <div class="head-logo" @click="logoClick"></div>
      <div class="head-right">
        <div class="user-icon" @click="userClick"></div>
        <div class="menu-icon" @click="menuClick"></div>
      </div>
    </div>
    <div class="nav-page" >
      <component :is="navUrl" style="margin-top: 56px;"></component>
    </div>
    <div class="nav-bottom">
      <div class="service-support-box">
        <div class="service-title">服务与支持</div>
        <div class="platform-box">
          <div class="agent-platform" @click="clickAgent">服务商后台</div>
          <div class="merchant-platform" @click="clickMerchant">商户后台</div>
        </div>
        <div class="service-line"></div>
      </div>
      <div class="contact-box">
        <div class="contact-title">联系我们</div>
        <div class="address">浙江·杭州·西湖区浙大森林A幢</div>
        <div class="phone">4000-818-661(服务时间 9:00~21:00)</div>
        <div class="email">ggyx@aduer.com</div>
      </div>
      <div class="code-box">
        <div class="code-img"></div>
        <div class="code-remind">微信扫一扫 关注我们</div>
        <div class="domain-name" @click="clickCopyright">浙ICP备19051656号-3</div>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602012974"
          class="recode-link"
        >
          <img class="recode-img" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/recode.png"/>
          <p class="recode-text">浙公网安备 33010602012974号</p>
        </a>
      </div>
    </div>
    <div :class="menuClass" v-if="menuClass">
      <div class="menu-bar">
        <div class="menu-title-icon" v-if="menuClass === '' || menuClass === 'menu-show-box'"></div>
        <div class="menu-hide-icon" @click="hideMenuClick"></div>
      </div>
      <div class="menu-list">
        <div class="menu-item" v-for="(item, index) of menuList" :key="index" @click="menuItemClick(item)">
          <div class="menu-item-name">{{ item.menuName }}</div>
          <div class="menu-item-arrow"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Solution from './MobileSolution.vue'
import AboutUs from './MobileAboutUs.vue'
import OpenPlatform from './MobileOpenPlatform.vue'
import ProductionCenter from './MobileProductionCenter.vue'
  export default {
    components: {
      Solution,
      AboutUs,
      OpenPlatform,
      ProductionCenter
    },
    data() {
      return {
        navUrl: '',
        menuClass: '',
        menuList: [
          { menuName: '首页', navUrl: 'home' },
          { menuName: '产品中心', navUrl: 'productionCenter' },
          { menuName: '解决方案', navUrl: 'solution' },
          { menuName: '开放平台', navUrl: 'openPlatform' },
          { menuName: '关于我们', navUrl: 'aboutUs' },
        ]
      }
    },
    props: {
      activeName: {
        type: String,
        value: ''
      }
    },
    watch: {
      activeName(newVal) {
        this.navUrl = newVal
      }
    },
    created () {
      this.navUrl = this.activeName
    },
    methods: {
      userClick() {
        this.$emit('editMenu', { menu: 'home', showRegister: true })
      },
      menuClick() {
        this.menuClass =  'menu-show-box' 
      },
      hideMenuClick() {
        this.menuClass = 'menu-hide-box'
        document.body.scrollTop = document.documentElement.scrollTop = 0
      },
      menuItemClick(item) {
        localStorage.setItem('activeName', item.navUrl)
        this.menuClass = 'menu-hide-box'
        if (item.navUrl === 'home') {
          this.$emit('editMenu', { menu: item.navUrl, showRegister: false })
        } else {
          this.navUrl = item.navUrl
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0
      },
      logoClick() {
        this.$emit('editMenu', { menu: 'home', showRegister: false })
      },
      clickAgent() {
        window.open('https://8.lianok.com/#/Login')
      },
      clickMerchant() {
        window.open('https://9.lianok.com/#/Login')
      },
      clickCopyright() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
    }
  }
</script>
<style lang="less" scoped>
  // .main-page {
  //   position: relative;
  // }
  .fix-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 100;
    .head-logo {
      margin-left: 15px;
      width: 176px;
      height: 25px;
      background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/menu_title_icon.png');
      background-repeat: no-repeat;
	    background-position: 0 0;
	    background-size: 100% auto;
    }
    .head-right {
      margin-left: auto;
      margin-right: 23px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .user-icon {
        width: 24px;
        height: 24px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/user_bold_icon.png');
        background-repeat: no-repeat;
	      background-position: 0 0;
	      background-size: 100% auto;
      }
      .menu-icon{
        margin-left: 18px;
        width: 24px;
        height: 24px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/menu_bold_icon.png');
        background-repeat: no-repeat;
	      background-position: 0 0;
	      background-size: 100% auto;
      }
      .menu-icon-hide {
        // margin-left: auto;
        margin-right: 23px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/hide_menu.png');
        background-repeat: no-repeat;
	      background-position: 0 0;
	      background-size: 100% auto;
      }
    }
  }
  .nav-bottom {
    width: 100%;
    background-color: #000;
    .service-support-box {
      margin: 0 16px 16px;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .service-title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
      }
      .platform-box {
        display: flex;
        margin-top: 8px;
        .agent-platform {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          cursor: pointer;
          transition: .3s;
        }
        .agent-platform:hover{
          font-weight: 500;
          color: #fff;
        }
        .merchant-platform {
          margin-left: 50px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          cursor: pointer;
          transition: .3s;
        }
        .merchant-platform:hover{
          font-weight: 500;
          color: #fff
        }
      }
      .service-line {
        margin-top: 16px;
        width: 100%;
        height: 0.5px;
        background: #E7E7E7;
        opacity: 0.3;
      }
    }
    .contact-box {
      margin: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .contact-title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
      }
      .address,
      .phone,
      .email {
        margin-top: 8px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .code-box {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .code-img {
        width: 110px;
        height: 110px;
        background-color: #fff;
        border-radius: 8px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/huolian_code.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 0 0;
      }
      .code-remind {
        margin-top: 4px;
        font-size: 12px;
        color: rgba(255,255,255,0.8);
      }
      .domain-name {
        margin: 16px 0 0;
        font-size: 10px;
        color: rgba(255,255,255,0.6);
        cursor: pointer;
        transition: .3s;
      }
      .recode-link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin: 0 0 20px;
      }
      .recode-img {
        width: 10px;
        height: 10px;
        margin: 3px;
      }
      .recode-text {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.6);
        transition: .3s;
      }
      .domain-name:hover,
      .recode-text:hover {
        font-weight: 500;
        color: #fff;
      }
    }
  }
  .menu-show-box, .menu-hide-box{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    animation-name: showmenu;
    animation-duration: 0.25s;
    z-index: 300;
    .menu-bar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 56px;
      border-bottom: 1px solid #e7e7e7;
      background-color: rgba(255, 255, 255, 0.1);
      .menu-title-icon {
        margin-left: 18px;
        width: 176px;
        height: 26px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/menu_title_icon.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% auto;
      }
      .menu-hide-icon {
        margin-left: auto;
        margin-right: 23px;
        width: 24px;
        height: 24px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/hide_menu.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        opacity: .6;
      }
    }
    .menu-list {
      width: 100%;
      z-index: 300;
      .menu-item {
        display: flex;
        align-items: center;
        width: 90%;
        height: 70px;
        margin: auto;
        border-bottom: 1px solid #e7e7e7;
        .menu-item-name {
          margin-left: 16px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
        }
        .menu-item-arrow {
          margin-left: auto;
          margin-right: 23px;
          width: 16px;
          height: 16px;
          background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/menu_arrow.png');
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% auto;
          opacity: .5;
        }
      }
    }
    
  }
  .menu-hide-box {
    left: 100%;
    animation-name: hidemenu;
    animation-duration: 0.25s;
  }
  @keyframes showmenu {
    0% { left: 100%;}
    100%{ left: 0%;} 
  }
  @keyframes hidemenu {
    0% { left: 0%;}
    100%{ left: 100%;} 
  }


</style>