<template>
  <div class="main-page">
    <div class="platform-top-bg" @click="checkPlatformFile"></div>
    <div class="platform-pay-scene">
      <div class="scene-title-box">
        <div class="scene-title">聚合支付场景</div>
        <div class="scene-des">满足各交易场景，完美接通主流支付渠道</div>
      </div>
      <div class="scene-item-list">
        <div class="scene-item" v-for="(item, index) of paySceneList" :key="index">
          <div >
            <img class="scene-item-icon" :src="item.sceneIcon">
          </div>
          <div class="scene-item-name">{{ item.sceneName }}</div>
        </div>
      </div>
    </div>
    <div class="platform-ability-box">
      <div class="ability-title-box">
        <div class="ability-title">接入更多的支付能力</div>
        <div class="ability-des">快速对接支付宝、微信等支付机构的能力，满足各种场景技术支持</div>
      </div>
      <div class="ability-list">
        <div class="ability-item" v-for="(item, index) of abilityList" :key="index">
          <img class="ability-item-icon" :src="item.abilityIcon">
          <div class="ability-item-name">{{ item.abilityName }}</div>
        </div>
      </div>
    </div>
    <div class="polymerize-pay-box">
      <div class="polymerize-title-box">
        <div class="polymerize-title">快速对接，1v1服务</div>
        <div class="polymerize-des">专业贴心技术服务团队，为你解决技术对接难点</div>
      </div>
      <div class="polymerize-list">
        <div class="polymerize-item" v-for="(item, index) of polymerizePayList" :key="index">
          <img class="polymerize-item-icon" :src="item.polymerizeIcon">
          <div class="polymerize-item-name">{{ item.polymerizeName }}</div>
          <div class="polymerize-item-content">{{ item.polymerizeContent }}</div>
        </div>
      </div>
    </div>
    <div class="platform-bottom">
      <div class="check-btn" @click="checkPlatformFile">查看文档</div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        paySceneList: [
          {
            sceneIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_scan_pay.png',
            sceneName: '扫码支付'
          },
          {
            sceneIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_face_pay.png',
            sceneName: '刷脸支付'
          },
          {
            sceneIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_mini_pay.png',
            sceneName: '小程序支付'
          },
          {
            sceneIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_official_pay.png',
            sceneName: '公众号支付'
          },
          {
            sceneIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_card_pay.png',
            sceneName: '银行卡支付'
          },
          {
            sceneIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_h5_pay.png',
            sceneName: 'H5支付'
          }
        ],
        abilityList: [
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_balance_share.png',
            abilityName: '余额分账'
          },
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_delay_settle.png',
            abilityName: '延时结算'
          },
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_scan_auth.png',
            abilityName: '扫码预授权'
          },
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_online_pay.png',
            abilityName: '线上通道支付'
          },
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_mini_plugin.png',
            abilityName: '小程序支付插件'
          },
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_online_receipt.png',
            abilityName: '线上收款单'
          },
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_auto_share.png',
            abilityName: '自动分账'
          },
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_seconds_receipt.png',
            abilityName: '笔笔秒到账'
          },
          {
            abilityIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_skip_support.png',
            abilityName: 'H5跳小程序支付'
          }
        ],
        polymerizePayList: [
          {
            polymerizeIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_develop_file.png',
            polymerizeName: '专业开发文档',
            polymerizeContent: '为开发者提供详尽的API文档、SDK及接入示例代码'
          },
           {
            polymerizeIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_wechat_service.png',
            polymerizeName: '1V1专属企业微信群',
            polymerizeContent: '7*12小时技术专家服务支持'
          },
           {
            polymerizeIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_quick_interface.png',
            polymerizeName: '快速对接联调',
            polymerizeContent: '帮助开发者快速联调，一键生成代码'
          }
        ]
      }
    },
    methods: {
      checkPlatformFile() {
        window.open('https://www.yuque.com/youyun-8yqqt/vpbgq7?')
      }
    }
  }
</script>
<style lang='less' scoped>
  .platform-top-bg {
    width: 100%;
    height: 180px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_top_bg.png');
    background-repeat: no-repeat;
    background-position: 0 0 ;
    background-size: 100% auto;
  }
  .platform-pay-scene {
    margin-bottom: 40px;
    .scene-title-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .scene-title {
        margin-top: 40px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
      .scene-des {
        margin-top: 10px;
        font-size: 14px;
        color: #333335;
      }
    }
    .scene-item-list {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .scene-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 33.3%;
        height: 100px;
        .scene-item-icon {
          width: 30px;
          height: 30px;
        }
        .scene-item-name {
          font-size: 14px;
          color: #000;
        }
      }
    }
  }
  .platform-ability-box {
    width: 100%;
    padding-bottom: 30px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/platform_ability_bg.png');
    background-repeat: no-repeat;
    background-position: 0 0 ;
    background-size: 100% auto;
    .ability-title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .ability-title {
        margin-top: 40px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
      .ability-des {
        margin: 10px 35px 0 35px;
        font-size: 14px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
      }
    }
    .ability-list {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .ability-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 33.3%;
        height: 100px;
        .ability-item-icon {
          width: 30px;
          height: 30px;
        }
        .ability-item-name {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  .polymerize-pay-box {
    background-color: #fff;
    .polymerize-title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .polymerize-title {
        margin-top: 40px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
      .polymerize-des {
        margin: 10px 35px 0 35px;
        font-size: 14px;
        line-height: 20px;
        color: #333335;
        text-align: center;
      }
    }
    .polymerize-list {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .polymerize-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.3%;
        .polymerize-item-icon {
          margin-top: 26px;
          width: 30px;
          height: 30px;
          
        }
        .polymerize-item-name {
          margin-top: 12px;
          font-size: 13px;
          font-weight: 500;
          color: #000;
        }
        .polymerize-item-content {
          padding: 0 10px;
          margin-top: 10px;
          font-size: 12px;
          color: #333335;
          text-align: center;
          line-height: 18px;
        }
      }
    }
  }
  .platform-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 130px;
    background-color: #fff;
    .check-btn {
      width: 90px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      background-color: #ff1c6d;
      border-radius: 14px;
      text-align: center;
    }

  }
</style>