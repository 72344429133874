<template>
  <div :class="['navigation', navigationClass, scrollTrue ? 'scroll-true' : '']">
    <img
      class="logo"
      src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_logo.png"
      alt="logo"
      @click="clickLogo"
    />
    <div class="nav-list">
      <div
        class="nav-item"
        v-for="(item, index) in navList"
        :key="index"
        @mouseenter="enterNav(item.key)"
        @mouseleave="leaveNav(item.key)"
        @click="clickNavItem(item.key)"
      >
        {{ item.label }}
        <div v-if="hoverActive===item.key" class="border-bottom active-border"></div>
        <div v-else class="border-bottom"></div>
      </div>
      
    </div>
    <div class="right-menu">
      <div class="sign-in" @click="clickLogin">登录</div>
      <div class="register" @click="clickRegister">免费注册</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        navList: [
          { label: '首页', key: 'home' },
          { label: '产品中心', key: 'productionCenter' },
          { label: '解决方案', key: 'solution' },
          { label: '开放平台', key: 'openPlatform' },
          { label: '关于我们', key: 'aboutUs' }
        ],
        hoverActive: 'home',
        clickActive: 'home',
        scrollTrue: false
      }
    },
    props: {
      navigationClass: {
        type: String,
        value: ''
      },
      activeName: {
        type: String,
        value: ''
      }
    },
    watch: {
      activeName(newVal) {
        this.clickActive = this.hoverActive = newVal
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
    },
    created() {
      this.clickActive = this.hoverActive = this.activeName
    },
    destroyed() {
      window.removeEventListener('scroll', this.handScroll, true)
    },
    beforeRouteLeave(to, from, next) {
      window.removeEventListener('scroll', this.handleScroll, true)
      next()
    },
    methods: {
      handleScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 0) {
          this.scrollTrue = true
        } else {
          this.scrollTrue = false
        }
      },
      enterNav(key) {
        this.hoverActive = key
      },
      leaveNav(key) {
        this.hoverActive = this.clickActive
      },
      clickNavItem(key) {
        this.clickActive = key
        this.$emit('clickNavItem', key)
        document.body.scrollTop = document.documentElement.scrollTop = 0
      },
      clickRegister() {
        this.hoverActive = 'home'
        this.clickActive = 'home'
        this.$emit('clickRegister')
      },
      clickLogin() {
        window.open('https://8.lianok.com/#/Login')
      },
      clickLogo() {
        this.hoverActive = 'home'
        this.clickActive = 'home'
        this.$emit('clickNavItem', 'home')
      }
    }
  }
</script>

<style lang="less">
  .scroll-true {
    backdrop-filter: blur(60px);
    -webkit-backdrop-filter: blur(60px);
  }
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    background: transparent;
    transition: background .2s ease-in-out;
    z-index: 111;
    .logo {
      width: 196px;
      height: 29px;
      margin: 0 40px 0 367px;
      cursor: pointer;
    }
    .nav-list {
      display: flex;
      .nav-item {
        height: 40px;
        padding: 0 19px;
        font-size: 16px;
        color: #FFF;
        cursor: pointer;
        line-height: 40px;
        transition: .3s;
      }
      .border-bottom {
        transform: scale(0);
        width: 100%;
        height: 3px;
        margin-top: -4px;
        border-radius: 3px;
        background-color: #FFF;
        transition: .3s;
      }
      .nav-item:hover {
        font-weight: 600;
        .border-bottom {
          transform: scale(1);
        }
      }
      .active-border {
        transform: scale(1);
      }
    }
    .right-menu {
      display: flex;
      margin-left: 218px;
      font-size: 18px;
      color: #FFF;
      .sign-in {
        width: 130px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        cursor: pointer;
      }
      .register {
        width: 130px;
        height: 40px;
        border-radius: 5px;
        line-height: 40px;
        background-color: #FF1C6D;
        cursor: pointer;
      }
    }
  }
</style>