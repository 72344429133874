<template>
  <div>
    <mobile-home v-if="activeName==='home'" :showRegister="showRegister" @editMenu="editMenu"></mobile-home>
    <mobile-nav v-else :activeName="activeName" @editMenu="editMenu"></mobile-nav>
  </div>
</template>

<script>
import MobileHome from './MobileHome.vue'
import MobileNav from './MobileNav.vue'
export default {
  components: { MobileHome, MobileNav },
  data() {
    return {
      activeName: 'home',
      showRegister: false
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  created() {
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('resize', this.handleResize)
    next()
  },
  methods: {
    handleResize() {
      this.activeName = localStorage.getItem('activeName') || 'home'
    },
    editMenu(data) {
      this.activeName = data.menu
      if (data.showRegister) {
        this.showRegister = true
      }
    }
  }
}
</script>

<style>

</style>