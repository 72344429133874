<template>
  <div>
    <div class="home-content">
      <div class="home-back">
        <transition name="animation" mode="out-in">
          <div key="regBox" class="regist-box" v-if="isRegistClicked">
            <div class="regist-code"></div>
            <div class="regist-scan-remind">具体详情可以扫码添加运营企业微信</div>
            <div class="regist-phone-remind">或致电: <span class="bold">4000-818-661</span> 进行咨询了解</div>
            <div class="sure-btn" @click="sureBtnClick">确认</div>
          </div>
          <div key="homeBox" class="regist-box" v-else>
            <div class="regist-introduce-icon"></div>
            <div class="introduce-content">火脸诞生于杭州，以“用火脸、多赚点”为使命，我们提供专业的移动支付收款、场景解决方案、供应链云资金、会员营销等服务，为用户创造更多收益。火脸整合支付通道、银行资源、SAAS系统，为支付服务商打造一套功能丰富、配置灵活的系统，为商户提供更多场景解决方案及营销功能。目前已经为服务200+支付服务商超过50000+门店使用火脸系统，我们为每一个合作的支付服务商提供专属1v1企业微信群7*12小时服务。</div>
            <div class="regist-btn" @click="registBtnClick">免费注册</div>
          </div>
        </transition>
      </div>
      <div class="head-bar">
        <div :class="['nav-bg', scrollTrue ? 'scroll-true' : '']"></div>
        <div class="head-logo" @click="logoClick"></div>
        <div class="head-right">
          <div class="user-icon" @click="userClick"></div>
          <div class="menu-icon" @click="menuClick"></div>
        </div>
      </div>
      <div class="home-bottom">
        <div class="service-support-box">
          <div class="service-title">服务与支持</div>
          <div class="platform-box">
            <div class="agent-platform" @click="clickAgent">服务商后台</div>
            <div class="merchant-platform" @click="clickMerchant">商户后台</div>
          </div>
          <div class="service-line"></div>
        </div>
        <div class="contact-box">
          <div class="contact-title">联系我们</div>
          <div class="address">浙江·杭州·西湖区浙大森林A幢</div>
          <div class="phone">4000-818-661(服务时间 9:00~21:00)</div>
          <div class="email">ggyx@aduer.com</div>
        </div>
        <div class="code-box">
          <div class="code-img"></div>
          <div class="code-remind">微信扫一扫 关注我们</div>
          <div class="domain-name" @click="clickCopyright">浙ICP备19051656号-3</div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602012974"
            class="recode-link"
          >
            <img class="recode-img" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/recode.png"/>
            <p class="recode-text">浙公网安备 33010602012974号</p>
          </a>
        </div>
      </div>
      <div :class="menuClass" v-if="menuClass">
        <div class="menu-bar">
          <div class="menu-title-icon"></div>
          <div class="menu-hide-icon" @click="hideMenuClick"></div>
        </div>
        <div class="menu-list">
          <div class="menu-item" v-for="(item, index) of menuList" :key="index" @click="menuItemClick(item)">
            <div class="menu-item-name">{{ item.menuName }}</div>
            <div class="menu-item-arrow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MobileNav from './MobileNav.vue'
  export default {
    components: {
      MobileNav
    },
    data() {
      return {
        isRegistClicked: false,
        showMenu: false,
        menuClass: '',
        menuList: [
          { menuName: '首页', navUrl: 'home' },
          { menuName: '产品中心', navUrl: 'productionCenter' },
          { menuName: '解决方案', navUrl: 'solution' },
          { menuName: '开放平台', navUrl: 'openPlatform' },
          { menuName: '关于我们', navUrl: 'aboutUs' },
        ],
        active: 'home',
        scrollTrue: false
      }
    },
    props: {
      showRegister: {
        type: Boolean,
        value: false
      }
    },
    watch: {
      showRegister(newVal) {
        this.isRegistClicked = newVal
      }
    },
    mounted () {
      window.addEventListener('scroll', this.handScroll, true)
    },
    created() {
      this.isRegistClicked = this.showRegister
    },
    destroyed() {
      window.removeEventListener('scroll', this.handScroll, true)
    },
    beforeRouteLeave(to, from, next) {
      window.removeEventListener('scroll', this.handScroll, true)
      next()
    },
    methods: {
      //滚动
      handScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 0) {
          this.scrollTrue = true
        } else {
          this.scrollTrue = false
        }
      },
      //获取滚动偏移量
      getScroll() {
        return {
          top: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
        }
      },
      //注册按钮点击
      registBtnClick() {
        this.isRegistClicked = true
      },
      //确认按钮点击
      sureBtnClick() {
        this.isRegistClicked = false
      },
      //用户点击
      userClick() {
        this.isRegistClicked = true
        // this.showMenu = false
      },
      menuClick() {
        this.menuClass = 'menu-show-box'
      },
      hideMenuClick() {
        this.menuClass = 'menu-hide-box'
        document.body.scrollTop = document.documentElement.scrollTop = 0
      },
      menuItemClick(item) {
        this.active = item.navUrl
        this.menuClass = 'menu-hide-box'
        if (item.navUrl !== 'home') {
          localStorage.setItem('activeName', item.navUrl)
          this.$emit('editMenu', { menu: item.navUrl, showRegister: false })
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0
      },
      logoClick() {
        this.active = 'home'
        this.isRegistClicked = false
      },
      clickAgent() {
        window.open('https://8.lianok.com/#/Login')
      },
      clickMerchant() {
        window.open('https://9.lianok.com/#/Login')
      },
      clickCopyright() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
    }
  }
</script>

<style lang="less">
  .home-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  .scroll-true {
    backdrop-filter: blur(60px);
    -webkit-backdrop-filter: blur(60px);
  }
  .home-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 790px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/home_back.png');
    background-repeat: no-repeat;
	  background-position: 0 0;
	  background-size: 100% 100%;
    .regist-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 308px;
      height: 323px;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 2px 40px 0px rgba(95,114,146,0.26);
      border-radius: 16px;
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      .regist-code {
        width: 132px;
        height: 132px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/regist_code.png');
        background-repeat: no-repeat;
	      background-position: 0 0;
	      background-size: 100% auto;
      }
      .regist-scan-remind {
        margin-top: 16px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
      .regist-phone-remind {
        margin-top: 2px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        .bold {
          font-weight: 600;
        }
      }
      .sure-btn {
        margin-top: 30px;
        width: 208px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background-color: #ff1c6d;
        border-radius: 8px;
        cursor: pointer;
      }
      .regist-introduce-icon {
        width: 175px;
        height: 26px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/introduce_title_icon.png');
        background-repeat: no-repeat;
	      background-position: 0 0;
	      background-size: 100% auto;
      }
      .introduce-content {
        margin: 16px 20px 0 20px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        color: #000;
        text-align: justify;
      }
      .regist-btn {
        margin-top: 20px;
        width: 208px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background-color: #ff1c6d;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
  
  .home-bottom {
    width: 100%;
    background-color: #000;
    .service-support-box {
      margin: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .service-title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
      }
      .platform-box {
        display: flex;
        margin-top: 8px;
        .agent-platform {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          cursor: pointer;
          transition: .3s;
        }
        .agent-platform:hover {
          font-weight: 500;
          color: #fff;
        }
        .merchant-platform {
          margin-left: 50px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          cursor: pointer;
          transition: .3s;
        }
        .merchant-platform:hover{
          font-weight: 500;
          color: #fff
        }
      }
      .service-line {
        margin-top: 16px;
        width: 100%;
        height: 0.5px;
        background: #e7e7e7;
        opacity: 0.3;
      }
    }
    .contact-box {
      margin: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .contact-title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
      }
      .address,
      .phone,
      .email {
        margin-top: 8px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .code-box {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .code-img {
        width: 110px;
        height: 110px;
        background-color: #fff;
        border-radius: 8px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/huolian_code.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 0 0;
      }
      .code-remind {
        margin-top: 3px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
      }
      .domain-name {
        margin: 16px 0 0;
        font-size: 10px;
        color: rgba(255, 255, 255, 0.6);
        transition: .3s;
        cursor: pointer;
      }
      .recode-link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin: 0 0 20px;
      }
      .recode-img {
        width: 10px;
        height: 10px;
        margin: 3px;
      }
      .recode-text {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.6);
        transition: .3s;
      }
      .domain-name:hover,
      .recode-text:hover {
        font-weight: 500;
        color: #fff;
      }
    }
  }
  .head-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    .nav-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .head-logo {
      margin-left: 15px;
      width: 176px;
      height: 25px;
      background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/head_logo.png');
      background-repeat: no-repeat;
	    background-position: 0 0;
	    background-size: 100% auto;
    }
    .head-right {
      margin-left: auto;
      margin-right: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .user-icon {
        width: 24px;
        height: 24px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/user_icon.png');
        background-repeat: no-repeat;
	      background-position: 0 0;
	      background-size: 100% auto;
      }
      .menu-icon {
        margin-left: 18px;
        width: 24px;
        height: 24px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/menu_icon.png');
        background-repeat: no-repeat;
	      background-position: 0 0;
	      background-size: 100% auto;
      }
    }
  }
  .menu-show-box, .menu-hide-box{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    animation-name: showmenu;
    animation-duration: 0.25s;
    .menu-bar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 56px;
      border-bottom: 1px solid #e7e7e7;
      z-index: 300;
      background-color: rgba(255, 255, 255, 0.1);
      .menu-title-icon {
        margin-left: 18px;
        width: 176px;
        height: 26px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/menu_title_icon.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% auto;
      }
      .menu-hide-icon {
        margin-left: auto;
        margin-right: 23px;
        width: 24px;
        height: 24px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/hide_menu.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        opacity: .6;
      }
    }
    .menu-list {
      width: 100%;
      z-index: 200;
      .menu-item {
        display: flex;
        align-items: center;
        width: 90%;
        height: 70px;
        margin: auto;
        border-bottom: 1px solid #e7e7e7;
        .menu-item-name {
          margin-left: 16px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
        }
        .menu-item-arrow {
          margin-left: auto;
          margin-right: 23px;
          width: 16px;
          height: 16px;
          background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/menu_arrow.png');
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 100% auto;
          opacity: .5;
        }
      }
    }
    
  }
  .menu-hide-box {
    left: 100%;
    animation-name: hidemenu;
    animation-duration: 0.25s;
  }
  @keyframes showmenu {
    0% { left: 100%;}
    100%{ left: 0%;} 
  }
  @keyframes hidemenu {
    0% { left: 0%;}
    100%{ left: 100%;} 
  }
  .animation-enter-from,
  .animation-leave-to {
    opacity: 0;
  }
  .animation-enter-active,
  .animation-leave-active {
    transition: opacity .4s ease;
  }
  .animation-enter-active {
    animation: identifier .4s ease;
  }
  .animation-leave-active {
    animation: identifier .4s ease reverse;
  }
  @keyframes identifier {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
</style>