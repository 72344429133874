<template>
  <div :class="['bottom', bottomClass]">
    <div class="bottom-box">
      <div class="service-support">
        <div class="title">服务与支持</div>
        <div class="content-item" @click="clickAgent">服务商后台</div>
        <div class="content-item" @click="clickMerchant">商户后台</div>
      </div>
      <div class="call-us">
        <div class="title">联系我们</div>
        <div class="call-item">浙江·杭州·西湖区浙大森林A幢</div>
        <div class="call-item">4000-818-661（服务时间9:00～21:00）</div>
        <div class="call-item">ggyx@aduer.com</div>
        <div></div>
        <div></div>
      </div>
      <div class="qr-code">
        <img class="code" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_qrcode.png" alt="">
        <div class="txt">微信扫一扫 关注我们</div>
      </div>
    </div>
    <div class="copyright" @click="clickCopyright">浙ICP备19051656号-3</div>
    <div class="record">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602012974"
        class="recode-link"
      >
        <img class="recode-img" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/recode.png"/>
        <p class="recode-text">浙公网安备 33010602012974号</p>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: {
      bottomClass: {
        type: String,
        value: ''
      }
    },
    created() {},
    methods: {
      clickAgent() {
        window.open('https://8.lianok.com/#/Login')
      },
      clickMerchant() {
        window.open('https://9.lianok.com/#/Login')
      },
      clickCopyright() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
    }
  }
</script>

<style lang="less">
  .bottom {
    padding-bottom: 15px;
  }
  .bottom-box {
    display: flex;
    .service-support,
    .call-us {
      width: 239px;
      padding-top: 60px;
      font-size: 16px;
      text-align: left;
      color: #FFF;
      .title {
        margin-bottom: 25px;
        font-weight: 600;
      }
      .content-item,
      .call-item {
        margin-bottom: 14px;
        opacity: .6;
        transition: .2s;
      }
      .content {
        line-height: 38px;
        text-shadow: 0px 2px 4px rgba(0,0,0,0.12);
      }
    }
    .service-support {
      margin: 0 100px 0 360px;
      .content-item {
        cursor: pointer;
      }
    }
    .call-us {
      width: 650px;
      margin-right: 50px;
    }
    .qr-code {
      padding-top: 60px;
      .txt {
        font-size: 16px;
        color: #FFF;
      }
      .code {
        width: 146px;
        height: 146px;
        margin-bottom: 8px;
      }
    }
  }
  .copyright {
    font-size: 16px;
    color: #FFF;
    opacity: 0.6;
    cursor: pointer;
  }
  .record {
    width: 100%;
    margin: 20px auto;
    text-align: center;
    .recode-link {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    .recode-img {
      width: 20px;
      height: 20px;
    }
    .recode-text {
      height: 20px;
      line-height: 20px;
      margin: 0px 0px 0px 5px;
      font-size: 16px;
      color: #FFF;
      opacity: 0.6;
    }
  }
</style>