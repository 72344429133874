<template>
  <div class="about-body">
    <div class="about-head"></div>
    <div class="brief-introduction" ref="aboutContent">
      <div class="brief-introduction-title">火脸简介</div>
      <div class="brief-introduction-content">火脸诞生于杭州，以“用火脸、多赚钱”为使命，我们提供专业的移动支付收款、场景解决方案、供应链云资金、会员营销等服务，为用户创造更多收益。火脸整合支付通道行业资源、 SAAS系统，为支付服务商打造一套功能丰富、配置灵活的作业系统，为商户提供多场景解决方案及营销功能。</div>
      <div class="brief-introduction-box">
        <img class="box-img" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_brief_introduction.png" alt="简介图片">
        <div class="box-content">
          <div class="box-title">服务对象</div>
          <div class="box-txt">成立至今我们服务200+支付服务商，超过50000+家门店使用火脸系统，其中海尔集团、华莱士、唐狮、李宁集团、云丁智能、甘其食、绿资西饼、麦趣尔集团等头部品牌选择火脸。</div>
          <div class="box-title">专业技术服务团队</div>
          <div class="box-txt">核心成员拥有超过10年以上移动支付从业经验，我们极其重视技术与服务，技术人员占比超过80%，我们为每一位合作的支付服务商提供专属1V1的企业微信群提供7*12小时服务。</div>
        </div>
      </div>
    </div>
    <div class="customer-stories">
      <div class="customer-stories-title">部分优质客户案例</div>
      <div class="customer-stories-list">
        <div class="stories-item" v-for="(item, index) in customerStoriesList" :key="index">
          <div class="stories-box" v-if="!item.morePlaceholder">
            <img class="stories-img" :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/'+item.logo" alt="案例图标">
            <div class="stories-content">{{ item.content }}</div>
            <div class="stories-name">
              <div class="name">{{ item.name }}<span class="part" v-if="item.isPart">（部分）</span></div>
              <img class="icon" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_stories-icon.png" alt="">
            </div>
          </div>
          <div class="placeholder-box" v-else>
            <div class="more-icon"><i class="icon"></i><i class="icon"></i><i class="icon"></i></div>
            <div class="more-placeholder">{{ item.morePlaceholder }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperative-modular">
      <div class="partner">
        <div class="modular-title">合作伙伴</div>
        <div class="modular-content">与行业领先的SaaS软件合作，提供更加完美技术解决方案</div>
        <div class="modular-list">
          <div class="list-item" v-for="(item, index) in partnerList" :key="index">
            <img
              class="list-img"
              :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/'+item"
              alt="合作伙伴"
            />
          </div>
        </div>
      </div>
      <div class="mechanism">
        <div class="modular-title">合作机构</div>
        <div class="modular-content">火脸携手优质的支付机构与银行，提供更加专业服务</div>
        <div class="modular-list pay-list">
          <div class="list-item" v-for="(item, index) in mechanismPayList" :key="index">
            <img
              class="list-img"
              :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/'+item"
              alt="支付机构"
            />
          </div>
        </div>
        <div class="modular-list bank-list">
          <div class="list-item" v-for="(item, index) in mechanismBankList" :key="index">
            <img
              class="list-img"
              :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/'+item"
              alt="合作银行"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="contact-us">
      <div class="contact-title">联系我们</div>
      <div class="contact-message">
        <img
          class="location-img"
          src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_location.png"
          alt="地图图片"
        />
        <div class="message">
          <div class="message-item name">
            <img class="icon" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_contact_name.png" alt="公司名称图标">
            杭州火脸科技有限公司
          </div>
          <div class="message-item">
            <img class="icon" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_contact_address.png" alt="公司地址图标">
            浙江·杭州·西湖区浙大森林A幢
          </div>
          <div class="message-item">
            <img class="icon" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_contact_phone.png" alt="公司电话图标">
            4000-818-661（服务时间9:00～21:00）
          </div>
          <div class="message-item">
            <img class="icon" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_contact_email.png" alt="公司邮箱图标">
            ggyx@aduer.com
          </div>
        </div>
      </div>
    </div>
    <pc-bottom bottomClass="about-bottom"></pc-bottom>
  </div>
</template>

<script>
  import PcBottom from './PcBottom.vue'
  export default {
    components: { PcBottom },
    data() {
      return {
        customerStoriesList: [
          {
            logo: 'pc_customer_stories1.png',
            content: '海尔集团是世界第四大白色家电制造商，也是中国电子信息百强企业之首。海尔集团在全面数字化转型发展中选择火脸成为合作伙伴，火脸为海尔全国近20,000家供应商提供支付及供应链解决方案。',
            name: '海尔集团'
          },
          {
            logo: 'pc_customer_stories2.png',
            content: '唐狮创立于1995年，品牌估值达64.26亿人民币，年销售规模达30多亿。火脸为唐狮全国近1000家门店提供支付、营销解决方案。',
            name: '唐狮'
          },
          {
            logo: 'pc_customer_stories3.png',
            content: '火脸为李宁近百家专卖店提供支付、收银解决方案，获得品牌区域一致好评，提升门店经营效率。',
            name: '李宁',
            isPart: true
          },
          {
            logo: 'pc_customer_stories4.png',
            content: '中国西式快餐连锁头部品牌，全国门店20,000+。火脸为华莱士品牌构建一体化供应链数字解决方案，全面提升总部管理效率。',
            name: '华莱士'
          },
          {
            logo: 'pc_customer_stories5.png',
            content: '中国智能家居领域的领军企业，火脸携手云丁为全国8,000+家集中式长租公寓提供解决方案。',
            name: '云丁智能'
          },
          {
            logo: 'pc_customer_stories6.png',
            content: '杭州本土知名餐饮连锁品牌，甘其食包子是第一家开在美国的中国包子连锁品牌，火脸为其近300家门店提供支付、营销、供应链服务。',
            name: '甘其食'
          },
          {
            logo: 'pc_customer_stories8.png',
            content: '绿资西饼创立于1992年，公司拥有25000多平方米的现代专业烘焙工厂，近200家门店，选择火脸作为其支付收银供应商，全面拥抱数字化转型。',
            name: '绿资西饼'
          },
          {
            logo: 'pc_customer_stories7.png',
            content: '麦趣尔集团股份有限公司是新疆维吾尔自治区规模较大、技术含量较高的现代化食品加工企业，A股上市公司，选择火脸成为其数字化经营供应商。',
            name: '麦趣尔集团'
          },
          {
            morePlaceholder: '更多案例请向运营进行咨询'
          }
        ],
        partnerList: [
          'pc_partner1.png',
          'pc_partner2.png',
          'pc_partner3.png',
          'pc_partner4.png',
          'pc_partner5.png',
          'pc_partner6.png'
        ],
        mechanismPayList: [
          'pc_mechanism_pay1.png',
          'pc_mechanism_pay2.png',
          'pc_mechanism_pay3.png',
          'pc_mechanism_pay4.png',
          'pc_mechanism_pay5.png',
          'pc_mechanism_pay6.png',
          'pc_mechanism_pay7.png',
          'pc_mechanism_pay8.png',
          'pc_mechanism_pay9.png',
          'pc_mechanism_pay10.png'
        ],
        mechanismBankList: [
          'pc_mechanism_bank1.png',
          'pc_mechanism_bank2.png',
          'pc_mechanism_bank3.png',
          'pc_mechanism_bank4.png',
          'pc_mechanism_bank5.png'
        ]
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
    },
    created() {},
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll, true)
    },
    beforeRouteLeave(to, from, next) {
      window.removeEventListener('scroll', this.handleScroll, true)
      next()
    },
    methods: {
      handleScroll() {
        const aboutContentTop = this.$refs.aboutContent ? this.$refs.aboutContent.getBoundingClientRect().top : ''
        if (aboutContentTop <= 84) {
          this.$emit('editNavClass', true)
        } else {
          this.$emit('editNavClass', false)
        }
      },
    } 
  }
</script>

<style lang="less">
  .about-head {
    height: 520px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_about_bg.png') no-repeat;
    background-size: cover;
  }
  .brief-introduction {
    padding: 60px 360px 120px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_brief_introduction_bg.png') no-repeat;
    background-size: cover;
    .brief-introduction-title {
      font-size: 48px;
      font-weight: 600;
      color: #000;
    }
    .brief-introduction-content {
      margin: 54px 0 68px;
      padding: 0 20px;
      text-align: left;
      line-height: 160%;
      font-size: 20px;
      color: #333335;
    }
    .brief-introduction-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 21px 70px 21px 32px;
      background-color: #FFF;
      .box-img {
        width: 386px;
        height: 296px;
        margin-right: 60px;
      }
      .box-content {
        text-align: left;
        margin-top: -20px;
        .box-title {
          margin: 20px 0 5px;
          font-size: 24px;
          font-weight: 500;
          color: #000;
        }
        .box-txt {
          font-size: 20px;
          line-height: 150%;
          color: #333335;
        }
      }
    }
  }
  .customer-stories {
    padding-bottom: 40px;
    overflow: hidden;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_customer_stories_bg.png') no-repeat;
    background-size: cover;
    .customer-stories-title {
      margin: 80px 0;
      font-size: 48px;
      font-weight: 600;
      color: #FFF;
    }
    .customer-stories-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 1620px;
      margin: auto;
      .stories-item {
        position: relative;
        width: 482px;
        margin: 0 24px 50px;
        padding-bottom: 115px;
        background-color: #fff;
        border-radius: 24px;
        box-shadow: 0px 2px 40px 0px rgba(95,114,146,0.26);
        overflow: hidden;
        text-align: left;
        cursor: pointer;
        transition: .2s;
        .stories-img {
          width: 170px;
          height: 80px;
          padding: 24px 0 0 30px;
        }
        .stories-content {
          padding: 0 30px;
          font-size: 18px;
          font-weight: 500;
          line-height: 160%;
          color: #000;
        }
        .stories-name {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          bottom: 34px;
          font-size: 28px;
          font-weight: 600;
          color: #000;
          .part {
            font-size: 20px;
            font-weight: normal;
            color: #333335;
          }
          .name {
            margin-left: 30px;
          }
          .icon {
            width: 95px;
            height: 56px;
            margin-right: 31px;
          }
        }
      }
      .stories-item:hover {
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_stories_hover.png') no-repeat;
        background-color: #FFF;
        background-size: 482px auto;
        transform: translate(0, -10px);
      }
      .stories-item:last-child {
        padding-bottom: 0;
      }
      .placeholder-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .more-icon {
          width: 90px;
          height: 20px;
          margin-bottom: 33px;
          .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            border-radius: 20px;
            background-color: #CDD1E1;
          }
        }
        .more-placeholder {
          font-size: 24px;
          font-weight: 500;
          color: #CDD1E1;
        }
      }
    }
  }
  .cooperative-modular {
    text-align: center;
    background-color: #F7F8FA;
    .partner,
    .mechanism {
      .modular-title {
        margin-top: 100px;
        font-size: 40px;
        font-weight: 600;
        color: #000;
      }
      .modular-content {
        margin: 16px 0 46px;
        font-size: 24px;
        color: #333335;
      }
      .modular-list {
        display: flex;
        justify-content: center;
        .list-item {
          width: 182px;
          height: 82px;
          margin: 0 13px;
          border-radius: 10px;
          transition: .2s;
          cursor: pointer;
          .list-img {
            width: 100%;
            height: 100%;
          }
        }
        .list-item:hover {
          box-shadow: 0px 2px 20px 0px #E1E3E6;
        }
      }
    }
    .partner {
      .modular-title {
        margin-top: 70px;
      }
      .modular-list {
        margin-bottom: 15px;
      }
    }
    .mechanism {
      margin-bottom: 115px;
      .pay-list {
        flex-wrap: wrap;
        width: 1320px;
        margin: 0 auto 25px;
        justify-content: center;
        .list-item {
          margin-bottom: 21px;
        }
      }
    }
  }
  .contact-us {
    padding: 144px 0 150px;
    text-align: center;
    background-color: #FFF;
    overflow: hidden;
    .contact-title {
      margin-bottom: 80px;
      font-size: 48px;
      font-weight: 600;
      color: #333335;
    }
    .contact-message {
      display: flex;
      justify-content: center;
      align-items: center;
      .location-img {
        width: 500px;
        height: 270px;
        margin-right: 80px;
      }
      .message {
        text-align: left;
        .message-item {
          display: flex;
          align-items: center;
          margin-bottom: 18px;
          font-size: 24px;
          color: #333335;
          .icon {
            width: 26px;
            height: 26px;
            margin-right: 12px;
          }
        }
        .name {
          margin-bottom: 35px;
          font-size: 32px;
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
  .about-bottom {
    background: #19191B;
    .content-item:hover,
    .copyright:hover,
    .recode-text:hover {
      opacity: 1;
    }
  }
</style>