<template>
  <div class="platform-body">
    <div class="platform-head">
      <div class="btn" @click="clickView"></div>
    </div>
    <div class="aggregate-pay" ref="platformContent">
      <div class="aggregate-pay-title">聚合支付场景</div>
      <div class="aggregate-pay-content">满足各种交易场景，完美接通主流支付渠道</div>
      <div class="aggregate-pay-list">
        <div
          class="pay-item"
          v-for="(item, index) in aggregatePayList"
          :key="index"
          @click="clickPayItem(item)"
        >
          <img
            class="item-img"
            :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/'+item.icon"
            alt="支付图标"
          />
          <div class="item-txt">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="more-pay">
      <div class="more-pay-title">接入更多的支付能力</div>
      <div class="more-pay-content">快速对接支付宝、微信等支付机构的能力，满足各种场景技术支持</div>
      <div class="more-pay-list">
        <div
          class="pay-item"
          v-for="(item, index) in morePayList"
          :key="index"
          @click="clickMorePayItem(item)"
        >
          <img
            class="item-img"
            :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/'+item.icon"
            alt="支付图标"
          />
          <div class="item-txt">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="quick-dock">
      <div class="quick-dock-title">快速对接，1v1服务</div>
      <div class="quick-dock-content">专业贴心技术服务团队，为你解决技术对接难点</div>
      <div class="quick-dock-list">
        <div class="dock-item" v-for="(item, index) in quickDockList" :key="index">
          <img
            class="item-img"
            :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/'+item.icon"
            alt="对接图标"
          />
          <div class="item-name">{{ item.name }}</div>
          <div class="item-txt">{{ item.content }}</div>
        </div>
      </div>
      <div class="view-btn" @click="clickView">查看文档</div>
    </div>
    <pc-bottom bottomClass="platform-bottom"></pc-bottom>
  </div>
</template>

<script>
  import PcBottom from './PcBottom.vue'
  export default {
    components: { PcBottom },
    data() {
      return {
        aggregatePayList: [
          {
            icon: 'pc_aggregate_pay1.png',
            label: '扫码支付',
            // ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/kwpmr4os41bdg7pq'
          },
          {
            icon: 'pc_aggregate_pay2.png',
            label: '刷脸支付',
            // ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/kwpmr4os41bdg7pq'
          },
          {
            icon: 'pc_aggregate_pay3.png',
            label: '小程序支付',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/yzixvetryt0lkkwt'
          },
          {
            icon: 'pc_aggregate_pay4.png',
            label: '公众号支付',
            // ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/kwpmr4os41bdg7pq'
          },
          {
            icon: 'pc_aggregate_pay5.png',
            label: '银行卡支付',
            // ownUrl: ''
          },
          {
            icon: 'pc_aggregate_pay6.png',
            label: 'H5支付',
            // ownUrl: ''
          }
        ],
        morePayList: [
          {
            icon: 'pc_more_pay1.png',
            label: '余额分账',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/ucxyb1mpbi7rgiqr'
          },
          {
            icon: 'pc_more_pay2.png',
            label: '延时结算',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/quisg3kwy238exow'
          },
          {
            icon: 'pc_more_pay3.png',
            label: '扫码预授权',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/quisg3kwy238exow'
          },
          {
            icon: 'pc_more_pay4.png',
            label: '线上通道支付',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7?'
          },
          {
            icon: 'pc_more_pay5.png',
            label: '小程序支付插件',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/bq9y4qh7plo33w4f'
          },
          {
            icon: 'pc_more_pay6.png',
            label: '线上收款单',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7?'
          },
          {
            icon: 'pc_more_pay7.png',
            label: '自动分账',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/ryy1zng3zv5odgmu'
          },
          {
            icon: 'pc_more_pay8.png',
            label: '笔笔秒到账',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/kw5qmnwabah5oedh'
          },
          {
            icon: 'pc_more_pay9.png',
            label: 'H5跳转小程序支付',
            ownUrl: 'https://www.yuque.com/youyun-8yqqt/vpbgq7/fazs3rkan7e2ar9o'
          }
        ],
        quickDockList: [
          {
            icon: 'pc_dock_pay1.png',
            name: '专业开发文档',
            content: '为开发者提供详尽的 API 文档、SDK备份'
          },
          {
            icon: 'pc_dock_pay2.png',
            name: '1v1专属企业微信群',
            content: '7*12小时技术专家服务支持'
          },
          {
            icon: 'pc_dock_pay3.png',
            name: '快速对接联调',
            content: '帮助开发者快速联调一键生成代码备份'
          }
        ]
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
    },
    created() {
    },
    destroyed() {
      window.removeEventListener('scroll', this.handScroll, true)
    },
    beforeRouteLeave(to, from, next) {
      window.removeEventListener('scroll', this.handleScroll, true)
      next()
    },
    methods: {
       handleScroll() {
        const platformContentTop = this.$refs.platformContent ? this.$refs.platformContent.getBoundingClientRect().top : ''
        if (platformContentTop <= 84) {
          this.$emit('editNavClass', true)
        } else {
          this.$emit('editNavClass', false)
        }
      },
      clickView() {
        window.open('https://www.yuque.com/youyun-8yqqt/vpbgq7?')
      },
      clickPayItem(item) {
        if (item.ownUrl) {
          window.open(item.ownUrl)
        } else {
          window.open('https://www.yuque.com/youyun-8yqqt/vpbgq7/kwpmr4os41bdg7pq')
        }
      },
      clickMorePayItem(item) {
        if (item.ownUrl) {
          window.open(item.ownUrl)
        } else {
          window.open('https://www.yuque.com/youyun-8yqqt/vpbgq7?')
        }
      }
    } 
  }
</script>

<style lang="less">
  .platform-head {
    height: 520px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_platform_bg.png') no-repeat;
    background-size: cover;
    overflow: hidden;
    .btn {
      width: 162px;
      height: 50px;
      margin: 370px auto;
      border-radius: 25px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      line-height: 50px;
      color: #FFF;
      background-color: transparent;
      cursor: pointer;
    }
  }
  .aggregate-pay,
  .more-pay,
  .quick-dock {
    padding-top: 73px;
    text-align: center;
    background-color: #FFF;
    .aggregate-pay-title,
    .more-pay-title,
    .quick-dock-title {
      font-size: 48px;
      font-weight: 600;
      color: #000;
    }
    .aggregate-pay-content,
    .more-pay-content,
    .quick-dock-content {
      margin: 20px 0 64px;
      font-size: 20px;
      color: #333335;
    }
  }
  .aggregate-pay {
    padding-bottom: 127px;
  }
  .aggregate-pay-list,
  .more-pay-list {
    display: flex;
    flex-wrap: wrap;
    width: 1240px;
    margin: auto;
    justify-content: center;
    align-items: center;
    .pay-item {
      width: 400px;
      height: 210px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: .3s;
      cursor: pointer;
      box-sizing: border-box;
      .item-img {
        width: 60px;
        height: 60px;
      }
      .item-txt {
        margin-top: 20px;
        font-size: 21px;
        color: #000;
      }
    }
  }
  .more-pay {
    padding-bottom: 57px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_platform_more_bg.png') no-repeat;
    background-size: cover;
    .more-pay-title,
    .more-pay-content {
      color: #FFF;
    }
    .more-pay-list {
      .pay-item {
        border: 1px solid transparent;
        .item-img {
          width: 60px;
          height: 60px;
          opacity: .8;
        }
        .item-txt {
          color: #FFF;
        }
      }
    }
  }
  .aggregate-pay-list .pay-item:hover {
    border: none;
    background-color: #F8F9FB;
  }
  .more-pay-list .pay-item:hover {
    background: rgba(255, 255, 255, 0.34);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    .item-img {
      opacity: 1;
    }
    .item-txt{
      font-weight: 500;
    }
  }
  .quick-dock {
    padding-bottom: 82px;
    .quick-dock-list {
      display: flex;
      width: 1200px;
      margin: auto;
      .dock-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 400px;
        height: 210px;
        .item-img {
          width: 60px;
          height: 60px;
        }
        .item-name {
          margin: 20px 0 12px;
          font-size: 21px;
          font-weight: 500;
          color: #000;
        }
        .item-txt {
          padding: 0 93px;
          font-size: 16px;
          line-height: 160%;
          color: #333335;
        }
      }
    }
    .view-btn {
      width: 160px;
      height: 50px;
      margin: 120px auto 0;
      border-radius: 25px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      line-height: 52px;
      color: #FFF;
      background: #FF1C6D;
      cursor: pointer;
    }
  }
  .platform-bottom {
    background: #19191B;
    .content-item:hover,
    .copyright:hover,
    .recode-text:hover {
      opacity: 1;
    }
  }
</style>