<template>
  <div>
    <pc-navigation
      @clickRegister="clickRegister"
      @clickNavItem="clickNavItem"
      :navigationClass="navigationClass"
      :activeName="activeName"
    ></pc-navigation>
    <pc-home ref="home" v-if="activeName==='home'" :show-register-box="showRegisterBox"></pc-home>
    <pc-product v-if="activeName==='productionCenter'" @editNavClass="editNavClass"></pc-product>
    <pc-solution v-if="activeName==='solution'" @editNavClass="editNavClass"></pc-solution>
    <pc-platform v-if="activeName==='openPlatform'" @editNavClass="editNavClass"></pc-platform>
    <pc-about v-if="activeName==='aboutUs'" @editNavClass="editNavClass"></pc-about>
  </div> 
</template>

<script>
  import PcNavigation from './PcNavigation.vue'
  import PcHome from './PcHome.vue'
  import PcProduct from './PcProduct.vue'
  import PcSolution from './PcSolution.vue'
  import PcPlatform from './PcPlatform.vue'
  import PcAbout from './PcAbout.vue'
  export default {
    components: { PcNavigation, PcHome, PcProduct, PcSolution, PcPlatform, PcAbout },
    data() {
      return {
        showRegisterBox: false,
        activeName: 'home',
        navigationClass: ''
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleResize)
      window.addEventListener('load', this.handleLoad)
    },
    created() {
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
      window.removeEventListener('load', this.handleLoad)
    },
    beforeRouteLeave(to, from, next) {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('load', this.handleLoad)
    next()
  },
    methods: {
      handleResize() {
        const localActive = localStorage.getItem('activeName')
        this.activeName = localActive
      },
      handleLoad() {
        this.activeName = 'home'
        localStorage.setItem('activeName', 'home')
      },
      clickRegister() {
        this.showRegisterBox = true
        this.activeName = 'home'
        localStorage.setItem('activeName', 'home')
        if (this.$refs.home) {
          this.$refs.home.clickRegister()
        }
      },
      clickNavItem(key) {
        if (key === 'home') {
          this.showRegisterBox = false
          if (this.$refs.home) {
            this.$refs.home.clickConfirm()
          }
        }
        localStorage.setItem('activeName', key)
        this.activeName = key
        this.navigationClass = ''
      },
      editNavClass(isEdit) {
        if (!isEdit) return this.navigationClass = ''
        this.navigationClass = 'black-bg'
      }
    } 
  }
</script>

<style scoped lang="less">
  .black-bg {
    background: rgba(0, 0, 0, .85);
    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);
  }
</style>
