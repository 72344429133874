<template>
  <div class="main-page">
    <div class="solution-top-bg" @click="checkSolutionClick"></div>
    <div class="solution-list">
      <div class="solution-item" v-for="(item, index) of solutionList" :key="index" @click="solutionItemClick(item)">
        <div>
          <img class="solution-icon" :src="item.solutionIcon">
        </div>
        <div class="solution-des">{{ item.solutionDes }}</div>
        <div class="solution-check" v-if="item.showCheck">查看解决方案 ></div>
      </div>
    </div>
    <div class="solution-bottom">
      <div class="check-btn" @click="checkSolutionClick">立即查看</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MobileSolution',
  data() {
    return {
      solutionList:[
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_mini_program.png',
          solutionDes: '实现自动化点单、配送、收款对账，商户可以通过该方案提高社群运营转化效率，可以创建各种社群营销活动。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcnwy73M6JH54j1jsV7vEgbWc'
        },
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_scan.png',
          solutionDes: '通过D0结算方式，实现收款实时到账，让资金回流速度更快；使用火脸旗下各种收银设备都可以实现扫码实时到账。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcnRG6lrPw8x9ectDO4rWwgMh'
        },
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_online_channel.png',
          solutionDes: '解决商家远程交易风控和各种风险提示等问题，支持教育培训、微商、家政等远程场景收款需求。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcnIAo9tazvzl0H6HSHpFuh6b'
        },
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_delay_settle.png',
          solutionDes: '解决0点经营场所，能够按照实际经营时间进行结算和对账问题；实现资金挂起押金自动退款。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcn8ZwbFdlUHl9ZHh9GKXF1hb'
        },
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_bill_share.png',
          solutionDes: '支持订单分账、余额分账、自动分账、对账管理、分账资金更加合规；支持场景为电商平台分润、抽佣等。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcnD0UvAcUv9pBylGJCrFJD7g'
        },
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_interface.png',
          solutionDes: '能够实现聚合支付、付款码支付、小程序支付、H5跳转小程序支付、APP跳转小程序支付等支付场景。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcnRMuF1aP1dMeo8wNquuWf4F'
        },
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_mini_plugin.png',
          solutionDes: '解决入件主体与交易主体不能支付问题，实现小程序主体下商户独立结算，比跳转小程序支付更加方便简单。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcnc8HLf9Kp2pg4dCMKr3byGe'
        },
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_chain_store.png',
          solutionDes: '可实现品牌总部和门店之间的支付和账户体系，为商户提供更专业、更便捷的服务，降低商户的管理成本。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcn1AIbYzcHo8OZTEpZgau66b'
        },
        {
          solutionIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_online_receipt.png',
          solutionDes: '解实现线上远程收款，资金流转更合规，风控概率低。帮助商家实时汇总付款信息，订单支付信息一目了然。',
          showCheck: true,
          checkLink: 'https://w5qeutov02.feishu.cn/wiki/wikcn7taiI3DkKjTZJ7hXu2GME5'
        }
      ]
    }
  },
  methods: {
    solutionItemClick(item) {
      const url = item.checkLink
      window.open(url)
    },
    checkSolutionClick() {
      window.open('https://w5qeutov02.feishu.cn/wiki/wikcnopyZeoroVoHlbcQ73lWhJf')
    }
  }
}
</script>
<style lang="less" scoped>
  .main-page {
    background-color: #f7f7f7;
    .solution-top-bg {
      width: 100%;
      height: 180px;
      background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/solution_top_bg.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% auto;
    }
    .solution-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .solution-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 345px;
        background-color: white;
        border-radius: 16px;
        border: 1px solid #E2E4EF;
        border-radius: 16px;
        margin-top: 24px;
        padding-bottom: 24px;
        .solution-icon {
          margin-top: 15px;
          width: 315px;
          height: 183px;
        }
        .solution-des {
          margin: 10px 15px 0;
          font-size: 14px;
          color: #000;
          text-align: left;
          line-height: 20px;
        }
        .solution-check {
          margin: 20px 16px 0 16px;
          font-size: 12px;
          font-weight: 500;
          color: #ff1c6d;
          text-align: left;
        }
      }
    }
    .solution-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 90px;
      background-color: #F6F6F6;
      .check-btn {
        width: 90px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: #fff;
        border-radius: 14px;
        background-color: #ff1c6d;
      }
    }
  }
  
</style>