<template>
  <div class="solution-body">
    <div class="solution-head">
      <div class="btn" @click="clickViewAll"></div>
    </div>
    <div class="solution-content" ref="solutionContent">
      <div
        class="solution-item"
        v-for="(item, index) in solutionList"
        :key="index"
        @click="clickSolutionItem(item)"
      >
        <div class="solution-img">
          <img class="img" :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/' + item.solutionImg" alt="">
        </div>
        <div class="solution-txt">{{ item.solutionContent }}</div>
        <div class="view-solution">
          查看解决方案
          <img class="icon" src="https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_right_arrow.png" alt="箭头" />
        </div>
      </div>
      <div class="view-btn" @click="clickViewAll">立即查看</div>
    </div>
    <pc-bottom bottomClass="solution-bottom"></pc-bottom>
  </div>
</template>

<script>
  import PcBottom from './PcBottom.vue'
  export default {
    components: { PcBottom },
    data() {
      return {
        solutionList: [
          {
            solutionImg: 'pc_solution1.png',
            solutionContent: '实现自动化点单、配送、收款对账，商户可以通过该方案提高社群运营转化效率，可以创建各种社群营销活动。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcnwy73M6JH54j1jsV7vEgbWc'
          },
          {
            solutionImg: 'pc_solution2.png',
            solutionContent: '通过D0结算方式，实现收款实时到账，让资金回流速度更快；使用火脸旗下各种收银设备都可以实现扫码实时到账。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcnRG6lrPw8x9ectDO4rWwgMh'
          },
          {
            solutionImg: 'pc_solution3.png',
            solutionContent: '解决商家远程交易风控和各种风险提示等问题，支持教育培训、微商、家政等远程场景收款需求。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcnIAo9tazvzl0H6HSHpFuh6b'
          },
          {
            solutionImg: 'pc_solution4.png',
            solutionContent: '解决0点经营场所，能够按照实际经营时间进行结算和对账问题；实现资金挂起押金自动退款。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcn8ZwbFdlUHl9ZHh9GKXF1hb'
          },
          {
            solutionImg: 'pc_solution5.png',
            solutionContent: '支持订单分账、余额分账、自动分账、对账管理、分账资金更加合规；支持场景为电商平台分润、抽佣等。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcnD0UvAcUv9pBylGJCrFJD7g'
          },
          {
            solutionImg: 'pc_solution6.png',
            solutionContent: '能够实现聚合支付、付款码支付、小程序支付、H5跳转小程序支付、APP跳转小程序支付等支付场景。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcnRMuF1aP1dMeo8wNquuWf4F'
          },
          {
            solutionImg: 'pc_solution7.png',
            solutionContent: '解决入件主体与交易主体不能支付问题，实现小程序主体下商户独立结算，比跳转小程序支付更加方便简单。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcnc8HLf9Kp2pg4dCMKr3byGe'
          },
          {
            solutionImg: 'pc_solution8.png',
            solutionContent: '可实现品牌总部和门店之间的支付和账户体系，为商户提供更专业、更便捷的服务，降低商户的管理成本。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcn1AIbYzcHo8OZTEpZgau66b'
          },
          {
            solutionImg: 'pc_solution9.png',
            solutionContent: '实现线上远程收款，资金流转更合规，风控概率低。帮助商家实时汇总付款信息，订单支付信息一目了然。',
            url: 'https://w5qeutov02.feishu.cn/wiki/wikcn7taiI3DkKjTZJ7hXu2GME5'
          }
        ]
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
    },
    created() {
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll, true)
    },
    beforeRouteLeave(to, from, next) {
      window.removeEventListener('scroll', this.handleScroll, true)
      next()
    },
    methods: {
      handleScroll() {
        const solutionContentTop = this.$refs.solutionContent ? this.$refs.solutionContent.getBoundingClientRect().top : ''
        if (solutionContentTop <= 84) {
          this.$emit('editNavClass', true)
        } else {
          this.$emit('editNavClass', false)
        }
      },
      clickSolutionItem(item) {
        window.open(item.url)
      },
      clickViewAll() {
        window.open('https://w5qeutov02.feishu.cn/wiki/wikcnopyZeoroVoHlbcQ73lWhJf')
      }
    } 
  }
</script>

<style lang="less">
  .solution-head {
    height: 520px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_solution_bg.png') no-repeat;
    background-size: cover;
    overflow: hidden;
    .btn {
      width: 200px;
      height: 50px;
      margin: 360px auto;
      cursor: pointer;
    }
  }
  .solution-content {
    width: 1210px;
    padding: 60px 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .solution-item {
      position: relative;
      width: 375px;
      margin: 0 13px 35px;
      padding-bottom: 45px;
      border: 1px solid #E2E4EF;
      border-radius: 20px;
      box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.08);
      background-color: #FFF;
      overflow: hidden;
      cursor: pointer;
      .solution-img {
        position: relative;
        width: 344px;
        height: 200px;
        margin: auto;
        margin: 16px auto 20px;
        border-radius: 20px;
        overflow: hidden;
        .img {
          width: 100%;
          height: 100%;
          transition: .2s;
        }
      }
      .solution-txt {
        padding: 0 16px;
        font-size: 14px;
        line-height: 160%;
        text-align: left;
        color: #000;
      }
      .view-solution {
        position: absolute;
        opacity: 0;
        bottom: 16px;
        left: 16px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #FF1C6D;
        cursor: pointer;
        transition: .2s;
        .icon {
          width: 12px;
          height: 12px;
        }
      }
    }
    .solution-item:hover  {
      .solution-img .img {
        transform: scale(1.05, 1.05);
      }
    }
    .solution-item:hover .view-solution {
      opacity: 1;
    }
    .solution-item:nth-child(3n) {
      margin-right: 0;
    }
    .view-btn {
      width: 160px;
      height: 50px;
      margin: 25px auto 0;
      border-radius: 25PX;
      font-size: 20px;
      font-weight: 600;
      line-height: 52px;
      color: #FFF;
      background-color: #FF1C6D;
      cursor: pointer;
    }
  }
  .solution-bottom {
    background: #19191B;
    .content-item:hover,
    .copyright:hover,
    .recode-text:hover {
      opacity: 1;
    }
  }
</style>