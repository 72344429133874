<template>
  <div class="home">
    <web-pc class="pc"></web-pc>
    <web-mobile class="mobile"></web-mobile>
  </div>
</template>

<script>
  import WebPc from '../components/WebPc'
  import WebMobile from '../components/WebMobile'
  export default {
    name: 'HomeView',
    components: { WebPc, WebMobile },
    data() {
      return {
      }
    },
    created() {
    }
  }
</script>

<style lang="less">
  .home {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
</style>
