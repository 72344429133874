<template>
  <div class="main-page">
    <div class="about-top-bg"></div>
    <div class="about-pay-bg">
      <div class="pay-scene-box">
        <div class="pay-scene-title">火脸简介</div>
        <div class="pay-scene-content">火脸诞生于杭州，以“用火脸、多赚钱”为使命，我们提供专业的移动支付收款、场景解决方案、供应链云资金、会员营销等服务，为用户创造更多收益。火脸整合支付通道行业资源、 SAAS系统，为支付服务商打造一套功能丰富、配置灵活的作业系统，为商户提供多场景解决方案及营销功能。</div>
      </div>
      <div class="service-team-box">
        <div class="service-team-icon">
          <img src="">
        </div>
        <div class="service-box">
          <div class="service-title">服务对象</div>
          <div class="service-content">成立至今我们服务200+支付服务商，超过50000+家门店使用火脸系统，其中海尔集团、华莱士、唐狮、李宁集团、云丁智能、甘其食、绿资西饼、麦趣尔集团等头部品牌选择火脸。</div>
        </div>
        <div class="team-box">
          <div class="team-title">专业技术服务团队</div>
          <div class="team-content">核心成员拥有超过10年以上移动支付从业经验，我们极其重视技术与服务，技术人员占比超过80%，我们为每一位合作的支付服务商提供专属1V1的企业微信群提供7*12小时服务。</div>
        </div>
      </div>
    </div>
    <div class="customer-example-box">
      <div class="example-title">部分优质客户案例</div>
      <div class="example-list">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) of exampleCustomerList" :key="index">
              <img class="example-company-icon" :src="item.companyIcon" style="background-color: red;">
              <div class="example-company-intro">{{ item.companyIntro }}</div>
              <div class="example-bottom-info">
                <div class="example-company-name">{{ item.companyName }}</div>
                <div class="example-card-icon"></div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="partner-box">
      <div class="partner-title">合作伙伴</div>
      <div class="partner-des">与行业领先的SaaS软件合作，提供更加完美技术解决方案</div>
      <div class="partner-icon"></div>
    </div>
    <div class="insititution-box">
      <div class="insititution-title">合作机构</div>
      <div class="insititution-des">火脸携手优质的支付机构与银行，提供更加专业服务</div>
      <div class="insititution-icon"></div>
    </div>
    <div class="contact-box">
      <div class="contact-map-box">
        <div class="contact-map-title">联系我们</div>
        <div class="contact-map-icon"></div>
      </div>
      <div class="contact-info-list">
        <div class="contact-info-item" v-for="(item, index) of contactInfoList" :key="index">
          <img class="info-item-icon" :src="item.infoIcon" style="width: 22px; height: 22px;">
          <div :class="item.isBoldStyle ? 'info-bold-content' : 'info-item-content'">{{ item.infoContent }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Swiper from 'swiper/bundle'
  import 'swiper/swiper-bundle.css'
  export default {
   data() {
      return {
        contactInfoList: [
          {
            infoIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_contact_company.png',
            infoContent: '杭州火脸科技有限公司',
            isBoldStyle: true
          },
          {
            infoIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_contact_address.png',
            infoContent: '浙江.杭州.西湖区浙大森林A幢',
            isBoldStyle: false
          },
          {
            infoIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_contact_phone.png',
            infoContent: '4000-818-661 (服务时间 9:00~21:00)',
            isBoldStyle: false
          },
          {
            infoIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_contact_email.png',
            infoContent: 'ggyx@aduer.com',
            isBoldStyle: false
          }
        ],
        exampleCustomerList:[
          {
            companyIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_company_haier.png',
            companyIntro: '海尔集团是世界第四大白色家电制造商，也是中国电子信息百强企业之首。海尔集团在全面数字化转型发展中选择火脸成为合作伙伴，火脸为海尔全国近20,000家供应商提供支付及供应链解决方案。',
            companyName: '海尔集团'
          },
           {
            companyIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_company_tonlion.png',
            companyIntro: '唐狮创立于1995年，品牌估值达64.26亿人民币，年销售规模达30多亿。火脸为唐狮全国近1000家门店提供支付、营销解决方案。',
            companyName: '唐狮'
          },
           {
            companyIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_company_lining.png',
            companyIntro: '火脸为李宁近百家专卖店提供支付、收银解决方案，获得品牌区域一致好评，提升门店经营效率。',
            companyName: '李宁'
          },
           {
            companyIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_company_hualaishi.png',
            companyIntro: '中国西式快餐连锁头部品牌，全国门店20,000+。火脸为华莱士品牌构建一体化供应链数字解决方案，全面提升总部管理效率。',
            companyName: '华莱士'
          },
           {
            companyIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_company_yunding.png',
            companyIntro: '中国智能家居领域的领军企业，火脸携手云丁为全国8,000+家集中式长租公寓提供解决方案。',
            companyName: '云丁智能'
          },
           {
            companyIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_company_ganqishi.png',
            companyIntro: '杭州本土知名餐饮连锁品牌，甘其食包子是第一家开在美国的中国包子连锁品牌，火脸为其近300家门店提供支付、营销、供应链服务。',
            companyName: '甘其食'
          },
           {
            companyIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_company_lvzi.png',
            companyIntro: '绿资西饼创立于1992年，公司拥有25000多平方米的现代专业烘焙工厂，近200家门店，选择火脸作为其支付收银供应商，全面拥抱数字化转型。',
            companyName: '绿姿西饼'
          },
           {
            companyIcon: 'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_company_maiquer.png',
            companyIntro: '麦趣尔集团股份有限公司是新疆维吾尔自治区规模较大、技术含量较高的现代化食品加工企业，A股上市公司，选择火脸成为其数字化经营供应商。',
            companyName: '麦趣尔集团'
          }
        ]
      }
    },
    mounted () {
      new Swiper('.swiper', {
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          dynamicMainBullets: 2
        },
        loop: true,
        autoplay: true,
        disableOnInteraction: false
      })
    },
    methods: {
      
    }
  }
</script>
<style lang="less" scoped>
  .main-page {
    background-color: #f7f8fa;
  }
  .about-top-bg {
    width: 100%;
    height: 180px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_top_bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
  }
  .about-pay-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_shadow_bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    .pay-scene-box {
      display: flex;
      flex-direction: column;
      justify-content:  flex-start;
      align-items: center;
      .pay-scene-title {
        margin-top: 36px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
      .pay-scene-content {
        margin-top: 20px;
        width: 345px;
        font-size: 14px;
        color: #333335;
        line-height: 22px;
        background-clip: text;
        text-align: left;
      }
    }
    .service-team-box {
      margin-top: 40px;
      padding-bottom: 35px;
      margin-bottom: 60px;
      width: 345px;
      background: #FFFFFF;
      border-radius: 16px;
      .service-team-icon {
        width: 100%;
        height: 238px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_pay_scene.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% auto;
      }
      .service-box {
        padding: 0 15px 0 15px;
        margin-top: 20px;
        .service-title {
          font-size: 18px;
          font-weight: 500;
          color: #000;
          text-align: left;
        }
        .service-content {
          margin-top: 12px;
          font-size: 14px;
          line-height: 22px;
          color: #333335;
          text-align: left;
        }
      }
      .team-box {
        margin-top: 24px;
        padding: 0 15px 0 15px;
        .team-title {
          font-size: 18px;
          font-weight: 500;
          color: #000;
          text-align: left;
        }
        .team-content {
          margin-top: 12px;
          font-size: 14px;
          line-height: 22px;
          color: #333335;
          text-align: left;
        }
      }
    }
  }
  .customer-example-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 402px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_example_bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    .example-title {
      margin-top: 20px;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      line-height: 56px;
    }
    .example-list {
      margin-top: 20px;
      width: 343px;
      height: 276px;
      background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/example_card_bg.png') no-repeat;
      background-size: 100% auto;
      border-radius: 16px;
      .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 276px;
        border-radius: 24px;
        .example-company-icon {
          margin: 20px 0 0 20px;
          width: 115px;
          height: 54px;
          background:rgba(0, 0, 0, 0.5);
        }
        .example-company-intro {
          margin: 8px 24px 0 24px;
          font-size: 14px;
          font-weight: 500;
          color: #000;
          text-align: left;
          line-height: 22px;
        }
        .example-bottom-info {
          display: flex;
          align-items: center;
          position: absolute;
          left: 24px;
          right: 24px;
          bottom: 20px;
          .example-company-name {
            font-size: 16px;
            font-weight: bold;
            color: #000;
            text-align: left;
          }
          .example-card-icon {
            margin-left: auto;
            width: 49px;
            height: 25px;
            background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_example_badge.png');
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 100% auto;
          }
        }
        
      }
    }
  }
  .partner-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .partner-title {
      margin-top: 40px;
      font-size: 20px;
      font-weight: bold;
      color: #000;
      line-height: 28px;
    }
    .partner-des {
      margin-top: 8px;
      font-size: 14px;
      color: #333335;
    }
    .partner-icon {
      margin-top: 24px;
      width: 346px;
      height: 86px;
      background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_partner.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% auto;
    }
  }
  .insititution-box {
    display: flex;
    flex-direction: column;
    align-items: center;
   
    .insititution-title {
      margin-top: 40px;
      font-size: 20px;
      font-weight: bold;
      color: #000;
      line-height: 28px;
    }
    .insititution-des {
      margin-top: 8px;
      font-size: 14px;
      color: #333335;
    }
    .insititution-icon {
      margin-top: 24px;
      width: 346px;
      height: 182px;
      background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_insitituation.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% auto;
    }
  }
  .contact-box {
    margin-top: 40px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    .contact-map-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .contact-map-title {
        margin-top: 30px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
      .contact-map-icon {
        margin-top: 20px;
        width: 345px;
        height: 186px;
        background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/about_map.png');
        background-repeat: no-repeat;
        background-position: 0 0 ;
        background-size: 100% auto;
      }
    }
    .contact-info-list {
      margin: 18px 0 30px;
      overflow: hidden;
      .contact-info-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .info-item-icon {
          margin-left: 14px;
          width: 22px;
          height: 22px;
        }
        .info-item-content {
          margin-left: 8px;
          font-size: 14px;
          color: #333335;
        }
        .info-bold-content {
          margin-left: 8px;
          font-size: 16px;
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
  .swiper{
    z-index: 1 !important;
    --swiper-theme-color: #1989fa;
    --swiper-pagination-color: #999999;/* 两种都可以 */
  }
</style>