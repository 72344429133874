<template>
  <div class="product-body">
    <div class="product-head"></div>
    <div class="product-content" ref="productContent">
      <div ref="leftNav" :class="['left-nav', whether ? 'is-fixed' : '']">
        <div
          :class="['nav-item', navActive===item.key ? 'nav-active' : '']"
          v-for="(item, index) in navList" 
          :key="index"
          @click="clickLeftNav(item.key, index)"
        >{{item.label}}</div>
      </div>
      <div class="product-item" v-for="(item, index) in deviceList" :key="index">
        <div class="item-title" :ref="'productTitle' + index">{{ item.title }}</div>
        <div class="item-device">
          <div class="device" style="minWidth: 210px" v-for="(dItem, dIndex) in item.device" :key="dIndex">
            <div
              class="device-img"
              :style="{ background: `url(https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/${dItem.deviceBg}) no-repeat`,
                        backgroundSize: 'cover' }"
            >
              <div class="device-mark"></div>
              <img
                class="img"
                :src="'https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/' + dItem.deviceImg"
                alt="设备图片"
              />
            </div>
            <div class="device-name">{{ dItem.deviceName }}</div>
            <div class="device-introduce">{{ dItem.deviceIntroduce }}</div>
            <div class="device-advantage">
              <div class="advantage-item" v-for="(aItem, aIndex) in dItem.deviceAdvantage" :key="aIndex">
                <div class="interval-line" v-if="aIndex"></div>
                <div>{{ aItem }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pc-bottom bottomClass="product-bottom"></pc-bottom>
  </div>
</template>

<script>
  import PcBottom from './PcBottom.vue'
  export default {
    components: { PcBottom },
    data() {
      return {
        whether: false,
        navList: [
          { label: '火脸收银设备', key: 'cashierEquipment' },
          { label: '火脸数字化应用', key: 'digitalApplication' },
          { label: '火脸插件', key: 'pluginUnit' }
        ],
        deviceList: [
          {
            title: '火脸收银设备',
            device: [
              {
                deviceImg: 'pc_device1.png',
                deviceBg: 'pc_device_bg1.png',
                deviceName: '火脸收款码牌',
                deviceIntroduce: '轻松搞定多平台收款的二维码；适用场景如社区小店、流动摊位，高频收银轻松应对，携带便捷。',
                deviceAdvantage: ['成本低', '聚合支付', '操作简单']
              },
              {
                deviceImg: 'pc_device2.png',
                deviceBg: 'pc_device_bg2.png',
                deviceName: '火脸POS-联迪A8',
                deviceIntroduce: '是目前行业最好用的收款工具，适用场景如医美、休闲娱乐、教育培训、连锁餐饮等，响应速度快，使用简单，安全性高。',
                deviceAdvantage: ['速度快', '使用简单', '性能强', '安全高']
              },
              {
                deviceImg: 'pc_device5.png',
                deviceBg: 'pc_device_bg5.png',
                deviceName: '火脸扫码王-联迪QM50S',
                deviceIntroduce: '语音播报不漏单，轻松掌握收银情况；可手持，也可置于桌面进行收银；视窗清晰，轻松被扫；4G + WiFi，通讯更稳定。',
                deviceAdvantage: ['防漏单', '大音量', '长待机']
              },
              {
                deviceImg: 'pc_device3.png',
                deviceBg: 'pc_device_bg3.png',
                deviceName: '火脸云音响',
                deviceIntroduce: '解决多平台收款的二维码；实时收款到账提醒，防止漏单；大容量电池续航时间更久，组装方便，适用于各种场所使用。',
                deviceAdvantage: ['防漏单', '大音量', '长待机', '组装方便']
              },
              {
                deviceImg: 'pc_device4.png',
                deviceBg: 'pc_device_bg4.png',
                deviceName: '火脸扫码盒',
                deviceIntroduce: '能够快速识别二维码，无需手动操作；轻松对接各种收银系统，实现完美兼容；语音提示，收银情况实时掌握；智能化设计，更加方便。',
                deviceAdvantage: ['识别快', '长待机', '操作简单', '组装方便']
              }
            ]
          },
          {
            title: '火脸数字化应用',
            device: [
              {
                deviceImg: 'pc_device6.png',
                deviceBg: 'pc_device_bg6.png',
                deviceName: '火脸收银APP',
                deviceIntroduce: '交易数据清晰明了，方便用户查看；实时账本，多门店管理，轻松对账记账；资金到账快，支付方式一目了然；可以下载收银物料。',
                deviceAdvantage: ['收款快', '对账简单', '结算清晰']
              },
              {
                deviceImg: 'pc_device7.png',
                deviceBg: 'pc_device_bg7.png',
                deviceName: '火脸GO小程序',
                deviceIntroduce: '清晰查看商户交易数据和佣金；无需电脑设备，直接为商户入件；可以直接在小程序获取实名认证码；方便物料下载及绑定收银设备。',
                deviceAdvantage: ['入件快', '操作简单', '使用便捷']
              }
            ]
          },
          {
            title: '火脸插件',
            device: [
              {
                deviceImg: 'pc_device8.png',
                deviceBg: 'pc_device_bg8.png',
                deviceName: '火脸收银插件',
                deviceIntroduce: '轻松改造传统收银台，满足更全面的收款需求；功能简单齐全，可完全接管你的收银台；支持多平台，适用的场景丰富。',
                deviceAdvantage: ['操作简单', '适配简单', '功能齐全']
              }
            ]
          }
        ],
        navActive: 'cashierEquipment',
        scrollBody: ''
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
    },
    created() {
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll, true)
    },
    beforeRouteLeave(to, from, next) {
      window.removeEventListener('scroll', this.handleScroll, true)
      next()
    },
    methods: {
      handleScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        const scrollBottom = document.documentElement.scrollHeight - scrollTop - document.documentElement.clientHeight
        const productContentTop = this.$refs.productContent ? this.$refs.productContent.getBoundingClientRect().top : ''
        const cashierEquipmentTop = this.$refs.productTitle0[0] ? this.$refs.productTitle0[0].getBoundingClientRect().top : ''
        const digitalApplicationTop = this.$refs.productTitle1[0] ? this.$refs.productTitle1[0].getBoundingClientRect().top : ''
        const pluginUnitTop = this.$refs.productTitle2[0] ? this.$refs.productTitle2[0].getBoundingClientRect().top : ''
        if (cashierEquipmentTop <= 150) {
          this.navActive = 'cashierEquipment'
        }
        if (digitalApplicationTop <= 150) {
          this.navActive = 'digitalApplication'
        }
        if (pluginUnitTop <= 150 || scrollBottom === 0) {
          this.navActive = 'pluginUnit'
        }

        if (productContentTop <= 34) {
          this.whether = true
        } else {
          this.whether = false
        }
        if (productContentTop <= 64) {
          this.$emit('editNavClass', true)
        } else {
          this.$emit('editNavClass', false)
        }
      },
      clickLeftNav(key, index) {
        const webHeight = document.body.clientHeight
        document.documentElement.scrollTop = this.$refs[`productTitle${index}`][0].offsetTop + this.AccMul(0.13, webHeight)
        this.navActive = key
      },
      AccMul(arg1 = 0, arg2 = 1){
        arg1 = Number(arg1); arg2 = Number(arg2)
        var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
        try { m += s1.split(".")[1].length } catch (e) { }
        try { m += s2.split(".")[1].length } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
      }
    } 
  }
</script>

<style lang="less">
  .product-head{
    height: 520px;
    background: url('https://aduerstatic.oss-cn-hangzhou.aliyuncs.com/Lianok_web/pc-web/pc_product_bg.png') no-repeat;
    background-size: cover;
  }
  .product-content {
    position: relative;
    padding: 15px 220px 0 220px;
    .left-nav {
      position: absolute;
      top: 30px;
      height: 600px;
      padding-top: 20px;
      border-left: 2px dashed #D8D8D8;
      overflow: hidden;
      .nav-item {
        height: 50px;
        padding: 0 18px 0 8px;
        border-left: 4px solid transparent;
        font-size: 16px;
        text-align: left;
        line-height: 50px;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        cursor: pointer;
        overflow: hidden;
      }
      .nav-active {
        border-left: 4px solid #FF1C6D;
        color: #FF1C6D;
        background-color: #FFF;
      }
    }
    .product-item {
      margin-left: 14%;
      text-align: left;
      .item-title {
        margin: 30px 0 32px 28px;
        font-size: 24px;
        font-weight: 600;
        color: #000;
        letter-spacing: 1px;
      }
      .item-device {
        display: flex;
        flex-wrap: wrap;
        .device {
          position: relative;
          width: 372px;
          margin: 0 0 40px 26px;
          padding-bottom: 90px;
          border: 1px solid #E2E4EF;
          border-radius: 25px;
          background-color: #FFF;
          overflow: hidden;
          cursor: pointer;
          transition: .2s;
          .device-img {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 250px;
            cursor: pointer;
            .img {
              width: 250px;
              z-index: 1;
            }
          }
          .device-name {
            margin: 24px 0 12px 24px;
            font-size: 24px;
            font-weight: 600;
            color: #000;
            letter-spacing: 1px;
          }
          .device-introduce {
            padding: 0 24px;
            font-size: 14px;
            line-height: 160%;
            color: #333335;
          }
          .device-advantage {
            position: absolute;
            left: 24px;
            bottom: 24px;
            display: flex;
            .advantage-item {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #999999;
              .interval-line {
                width: 1px;
                height: 10px;
                margin: 0 8px;
                background-color: #C6C6C6;
              }
            }
          }
          .device-mark {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: .2s;
            background: linear-gradient(180deg, rgba(18, 67, 216, 0), rgba(18, 67, 216, 0.8));
          }
        }
        .device:hover {
          transform: translate(0, -10px);
        }
        .device:hover .device-mark {
          opacity: 1;
        }
      }
    }
  }
  .is-fixed {
    position: fixed !important;
    margin-top: 38px;
  }
  .product-bottom {
    background: #19191B;
    .content-item:hover,
    .copyright:hover,
    .recode-text:hover {
      opacity: 1;
    }
  }
</style>